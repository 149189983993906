import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PostList from '../components/Posts/PostList';
import { StandardHeader } from '../components/Acf/StandardHeader';
import { PageIntro } from '../components/Acf/PageIntro';
import { ContactCtaBlock } from '../components/Acf/ContactCtaBlock';
import { decodeEntities } from '../utils/helpers';

export default class Category extends React.Component {
  render() {
    const { data, pageContext, location } = this.props
    const { wordpressPost: projectsPage = [], postsOnPage, categoryFilter, categories, site, wordpressWpSettings, siteSettings } = data
    const { showAuthor } = siteSettings.options
    const { edges: posts, totalCount } = postsOnPage
    const { title: siteTitle } = wordpressWpSettings
    const { name: category, slug } = pageContext
    const title = `${category} Category`
    const thisCategory = categories.edges.filter(i => i.node.slug === slug)
    const featuredImage = thisCategory[0] && thisCategory[0].node.featured_image_url ? categories.edges[0].node.featured_image_url : ''
    const { yoast, acf } = projectsPage ? projectsPage : { title: null, yoast: {}, acf: {} }
    const {
      // Standard Header fields
      standardHeaderTitleBold,
      standardHeaderTitleRegular,
      standardHeaderSubtitleBold,
      standardHeaderSubtitleRegular,
      standardHeaderImage,
      // Page Intro fields
      pageIntroTitleBold,
      pageIntroTitleRegular,
      pageIntroContent,
      pageIntroImage,
      // Contact CTA Block fields
      contactCtaTitleBold,
      contactCtaTitleRegular,
      contactCtaContent,
      contactCtaLink,
      contactCtaImage,
    } = acf;
    return (
      <Layout className={`page-wrap`}>
        <SEO
          title={`${
            yoast.metaTitle ?
            yoast.metaTitle :
            `${decodeEntities(category)} Projects | ${decodeEntities(siteTitle)}`
          }`}
          desc={yoast.metaDescription}
          yoast={yoast}
        />
        <StandardHeader
          pageTitle={title}
          standardHeaderTitleBold={standardHeaderTitleBold}
          standardHeaderTitleRegular={standardHeaderTitleRegular}
          standardHeaderSubtitleBold={standardHeaderSubtitleBold}
          standardHeaderSubtitleRegular={standardHeaderSubtitleRegular}
          standardHeaderImage={standardHeaderImage}
          location={location}
        />
        <PageIntro
          pageIntroTitleBold={pageIntroTitleBold}
          pageIntroTitleRegular={pageIntroTitleRegular}
          pageIntroContent={pageIntroContent}
          pageIntroImage={pageIntroImage}
        />
        <PostList
          posts={posts}
          title={title}
          pageContext={pageContext}
          categoryFilter={categoryFilter}
          siteMetadata={wordpressWpSettings}
          pathPrefix={wordpressWpSettings.blogSlug}
          pathSuffix={`category/${slug}/`}
          showAuthor={showAuthor}
        />
        <ContactCtaBlock
          contactCtaTitleBold={contactCtaTitleBold}
          contactCtaTitleRegular={contactCtaTitleRegular}
          contactCtaContent={contactCtaContent}
          contactCtaLink={contactCtaLink}
          contactCtaImage={contactCtaImage}
        />
      </Layout>
    )
  }
}

Category.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query CategoryPage($slug: String!, $limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      blogSlug
      wordpressUrl
      siteUrl
    }
    siteSettings: wordpressAcfOptions(options: {}) {
      options {
        showAuthor
      }
    }
    wordpressPost: wordpressPage(slug: {eq: "news"}) {
      title
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
      acf {
        ... StandardHeaderNonFlexibleQuery
        ... PageIntroNonFlexibleQuery
        ... ContactCtaBlockNonFlexibleQuery
      }
    }
    categoryFilter: allWordpressPost {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          id
        }
      }
    }
    postsOnPage: allWordpressPost(
      filter: {
        categories: {elemMatch: {slug: {eq: $slug}}},
        slug: { ne: "gatsby-demo" }
      }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          featured_image_url {
            source_url
          }
          count
        }
      }
    }
  }
`
